import React, {useEffect} from 'react'

// GSAP 
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

// STYLING
import '../Styling/HireMe.css'
import '../Styling/Services.css'

// Framer-motion component
import {motion} from 'framer-motion'

// GSAP Component for transitions
import {Power3} from 'gsap'

// Countdown
import Countdown from 'react-countdown'

export default function HireMe(){
    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger);
    }, [])

    return (
        <div>
            {/* Page transitions */}
            <motion.div
                className = "fixed pageEnter"
                initial = {{ y:'0', zIndex:10}}
                animate = {{y:'-100vw'}}
                transition = {{delay: 1, duration: 0.6, ease : Power3.easeIn}}
            />
            <motion.div
                className = "fixed pageExit"
                animate = {{opacity: 0}}
                exit = {{opacity : 1}}
                transition = {{duration: 1, ease : Power3.easeOut}}
            />

            {/* ACTUAL PAGE */}
            <div className = "HireMe border flex">
        
                <h1 className = "title">ENGAGEZ MOI</h1>
                <div className = "countdown">
                <Countdown 
                    date={Date.now() + 1000000000} 
                />
                </div>

                <div> <a href = "https://alexandrepujol.com/">Mon portfolio</a></div>
            </div>
        </div>
    )
}