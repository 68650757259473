export default function getWords(){
  // Put the words between span tags
  const awards = document.querySelectorAll('.Awards .paragraph');

  for(let i = 0; i < awards.length; i++){
    awards[i].innerHTML = awards[i].innerText.split(/\s/).map(function(word) {
    return '<span class = "container"><span class="word" >' + word + '</span></span>'
    // return '<span class="word" >' + word + '</span>'
    }).join(' ')
  }

  // Put the words in an array
  let words = document.querySelectorAll('.Awards span')

  return words
}
