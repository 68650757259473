import React , { useEffect } from 'react'

// GSAP 
import {gsap, Power3} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

// STYLING
import '../Styling/Services.css'
import '../Styling/Footer.css'

// PHOTO
import selfPortrait from '../Assets/Photos/photoRando.png'

export default function Footer(){

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)

        let tl = gsap.timeline({
            scrollTrigger : {
                trigger:'.Footer',
                start:'top-=200px bottom',
                end:'bottom top',
                scrub:true,
                toggleActions:'play none none reverse'
            }
        })

        let tl2 = gsap.timeline({
            scrollTrigger : {
                trigger : '.finalBanner',
                start:'top-=200px center',
                toggleActions:'play none none reverse',
            }
        })

        tl.fromTo('.rollingBanner', {
            x:'100%'
        },{
            x:'-100%'
        })

        tl2.from('.finalBanner .Photo',{
            y : '30%',
            opacity: 0,
            duration:1,
            ease: Power3.easeIn
        })
        tl2.from('.finalBanner .Description',{
            y : '30%',
            opacity: 0,
            duration:1,
            ease: Power3.easeIn,
            

        },"-=0.5")
    }, [])

    return(
        <div className = "Footer">
            <div className = "rollingBanner flex">
                <div className = "item flex">
                    <div className = "Main"> 100% </div>
                    <div className = "Secondary"> autonome </div>
                </div>
                <div className = "item flex">
                    <div className = "Main"> 100% </div>
                    <div className = "Secondary"> motivé </div>
                </div>
                <div className = "item flex">
                    <div className = "Main"> 1994 </div>
                    <div className = "Secondary"> mon année de naissance </div>
                </div>
            </div>
            
            <div className = "finalBanner">
                <div className = "container grid">
                    <div className = "Photo">
                        <h2> Alexandre Pujol </h2>
                        <div>
                            <img alt = "portrait" src = {selfPortrait} className = "resp"></img>
                        </div>
                    </div>

                    <div className = "Description">
                        <h2> En bref </h2>
                        <p> Après deux ans de classes préparatoires, un master en marketing international, plusieurs 
                        experiences professionnelles dans le monde de la culture, une petite carrière dans 
                        l'illustration, je me suis lancé dans l'aventure du développement web, domaine dont 
                        je suis tombé amoureux il y a deux ans lorsque j'ai lancé mon premier bootcamp sur 
                        internet. Après une formation accélérée je pense pouvoir être devenir un véritable
                        atout multifacette pour adveris.</p>
                        <h2> Ah et aussi ... </h2>
                        <p> Je parle couramment l'Anglais et l'Espagnol, j'ai une excellente capacité à 
                        travailler en équipe. Au cours de ma petite carrière j'ai eu l'occasion de collaborer
                        avec beaucoup de corps de métier différents et ai énormément appris de ces différentes
                        expériences. </p>
                    </div>
                </div>
            </div>
        </div>
    )

}