import GetWords from './GetWords'

export default function getLines(){
    // Puts the words between span tags
    GetWords()

    //Creates an array with all the lines
    let lines = [];
    let line;
    let words = document.querySelectorAll('.word');

    let lastTop;
    for (let i = 0; i < words.length; i++) {
        let word = words[i];
        if (word.offsetTop !== lastTop) {
        lastTop = word.offsetTop;
        lines.push(line);
        line = [];
        }
        line.push(word);
    }
    return lines;
}