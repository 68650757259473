import React, { useEffect } from 'react'

// Function to divide the paragraphs into spans for each words
import GetLines from '../Utilitaries/GetLines'

// GSAP 
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'


// STYLING
import '../Styling/Services.css'
import '../Styling/Awards.css'

export default function Awards () {
   
    useEffect(() => {
        // Function called from the Utilitaries folder : divides the p in several spans. One for each word.
        let lines = GetLines()
        console.log(lines)

        // Enabling the ScrollTrigger plugin from gsap
        gsap.registerPlugin(ScrollTrigger)

        // The words will be animated, the containers will be set on "overflow: hidden" 
        let words = gsap.utils.toArray('.Awards .paragraph .word')

        let tl = gsap.timeline({
            scrollTrigger : {
                trigger:'.Awards',
                start:'top center',
                end:'center-=100px center',
                scrub:true,
            }
        })
        
        tl.fromTo(words,{
            y: "110%",
            stagger:0.1,
            rotate:6
        },{
          y: -5,
          rotate:0,
          stagger:0.1  
        })

    }, [])
    
    return (
        <div className = "Awards"> 
            <div className="paragraph" style={{display:'inline-block'}}> 💻 Jeune développer front motivé et touche à tout, stack maitrisée : Wordpress, PHP, SQL, ReactJS</div>
            <div className="paragraph"> J'ai également des compétences en design - suite Adobe, Figma, Procreate et suis illustrateur à mes heures perdues (@Serotoninene sur Instagram) </div>
        </div>
    )
}


