import React, {Component} from 'react'

// STYLING
import '../Styling/Services.css'
import '../Styling/HeroBanner.css'

// VIDEO
import Adveris_video from '../Assets/Videos/adveris.mp4'

class HeroBanner extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){


        return(
            <div className = 'HeroBanner'>

                {/* BACKGROUND VIDEO */}
                <div className = 'bg_video absolute'>
                    <div className = "video_filter absolute"></div>
                    <video className = "video" autoPlay="autoplay"  loop preload="true" muted width="100%" height="100%">
                        <source src= {Adveris_video} type="video/mp4"/>
                    </video>
                </div>

                {/* TEXT */}
                <div className = "Hero_text">
                    <h1> Parce que les actes sont plus éloquents que les mots, j'ai recodé votre site.</h1>

                    <p> Découverte en 2020 lorsqu'elle fut élue "Agence Digitale de l'Année", je suis
                    avec grand intérêt Adveris depuis ce jour. De par son impressionnant palmarès
                    et son expertise dans une pluralité de domaines du web, votre compagnie me semble 
                    la plus à même de développer (jeu de mot totalement recherché) encore mes compétences 
                    et de m'épanouir pleinement au sein d'une structure en adéquation avec mes 
                    valeurs et ambitions. </p>

                    <div className = "Hero_footer flex"> 
                        <p> SCROLL </p>
                        <div className = "Ball"></div>
                        <p> <a href = "https://alexandrepujol.com/"> Mon Portfolio </a> </p>
                    </div>
                </div>

            
            </div>
        )
    }
}

export default HeroBanner