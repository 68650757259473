import React from 'react'

// React-router components
import { Switch, Route } from 'react-router-dom'

// Components
// import Cursor from './Components/Cursor'
import Homepage from './Components/Homepage'
import HireMe from './Components/HireMe';

// framer-motion
import { AnimatePresence } from 'framer-motion'

// Styling
import './App.css';
import './Styling/Cursor.css'

function App(props) {
// const liens = document.querySelectorAll('a');

// MOVING THE CUSTOM CURSOR
// I first wanted to give the x and y position to the 
// Cursor component as props but by rerendering it multiple times it got 
// the whole website laggy so I just modified its style through vanilla JS
//   const handleMove = (e) => {
//     const cursor = document.querySelector('.Cursor');
//     cursor.style.left = `${e.pageX - 15}px` 
//     cursor.style.top = `${e.pageY - 15}px` 
//   }

  // EXPANSION OVER LINKS
  // For it to retract and contract when it hovers over a link, I did also
  // with Vanilla JS
  // for (let i = 0; i < liens.length; i ++){
  //   const cursor = document.querySelector('.Cursor');
  //   liens[i].addEventListener('mouseover', function(){
  //     console.log('on link')
  //     cursor.classList.add('expand');
  //   })

  //   liens[i].addEventListener('mouseout', function(){
  //     cursor.classList.remove('expand')
  //   })
  // }

  return (
    <div className="App" >
      <Route 
        render = {({location}) => (
          <AnimatePresence exitBeforeEnter>      
          <Switch location = {location} key={location.pathname}>
            <Route
              exact
              path = '/'
              render = {()=> 
                  <Homepage />
              }
            />
            <Route
              exact
              path = '/HireMe'
              render = {()=> 
                  <HireMe />
              }
            />

          </Switch>
        </AnimatePresence>
        )}>
      </Route>
    </div>    
  );
}

export default App;
