import React, {Component} from 'react'

// React Router Links
import {NavLink} from 'react-router-dom'

// GSAP 
import {gsap, Power3} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

// Function to get the position of the scroll
// import getScrollPosition from '../Utilitaries/getScrollPosition'

// CSS Styling 
import '../Styling/Navbar.css'
import '../Styling/Services.css'

// Logos 
import Light_Instagram from '../Assets/Logos/white_instagram.png'
import Light_Linkedin from '../Assets/Logos/white_linkedin.png'
import Light_Github from '../Assets/Logos/white_github.png'


class Navbar extends Component{
    constructor(props){
        super(props)
        this.state = {
        }
    }



    componentDidMount(){          
        // animation de la navbar avec gsap
        gsap.registerPlugin(ScrollTrigger);
        let Components = document.querySelectorAll('.Navbar *')

        let tl = gsap.timeline({
            scrollTrigger : {
                trigger:'.HeroBanner',
                start: 'bottom+=205px top+=50px',
                toggleActions : 'play none none reverse',
                id:'navbar',
            }
        })

        tl.to('.Navbar',{
          backgroundColor: '#FFFFFF',
          ease: Power3.ease,
          duration: 0.2
        })

        tl.to('.Navbar .Reseaux',{
            opacity:0, 
            display:"none",
            ease: Power3.ease,
            duration: 0.2
        })

        tl.to(Components,{
            color: '#000000',
            ease: Power3.ease,
            duration: 0.2
        })


    }

    render(){

        return (
            <div className = "Navbar fixed">
                <div className = "container flex">
                    <div className = "Logo ">
                        ALEXANDRE PUJOL
                    </div>

                    <div className = "Liens  flex ">

                        <div className = "Reseaux flex ">
                            <NavLink exact to = "/HireMe" className="NavLink" activeClassName = "activeStyle"> Blog </NavLink>
                            <NavLink exact to = "/HireMe" className="NavLink" activeClassName = "activeStyle"> Recrutement </NavLink>
                            <a href = "https://www.instagram.com/serotoninene/"> <img alt= "logo Instagram"src= {Light_Instagram} className=""></img> </a>
                            <a href = "https://www.linkedin.com/in/alexandre-pujol-333172b3/"> <img alt= "logo Linkedin"src= {Light_Linkedin} className=""></img> </a>
                            <a href = "https://github.com/Serotoninene"> <img alt= "logo Github"src= {Light_Github} className=""></img> </a>
                        </div>

                        <div className = "Navigation flex">
                            <NavLink exact to = "/HireMe" className="NavLink" activeClassName = "activeStyle"> ACCUEIL </NavLink>
                            <NavLink exact to = "/HireMe" className="NavLink" activeClassName = "activeStyle"> EXPERTISES </NavLink>
                            <NavLink exact to = "/HireMe" className="NavLink" activeClassName = "activeStyle"> PROJETS </NavLink>
                            <NavLink exact to = "/HireMe" className="NavLink" activeClassName = "activeStyle"> SECTEURS </NavLink>
                            <NavLink exact to = "/HireMe" className="NavLink" activeClassName = "activeStyle"> AGENCE </NavLink>
                            <NavLink exact to = "/HireMe" className="NavLink" activeClassName = "activeStyle"> CONTACT </NavLink>
                        </div>
                    </div>
                </div>
            </div>  
        )
    }
}

export default Navbar