import React, { Component } from 'react'

//Components 
import Navbar from './Navbar'
import HeroBanner from './HeroBanner'
import Awards from './Awards'
import Footer from './Footer'
import HireMe from './HireMe'


//Framer-Motion components
import {motion} from 'framer-motion'

// GSAP components
import { Power3 } from 'gsap'


// Styling
import '../Styling/Services.css'
import '../Styling/Homepage.css'
import '../Styling/Cursor.css'



class Homepage extends Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }

    // componentDidMount(){
    //     const liens = document.querySelectorAll('a');

    //     for (let i = 0; i < liens.length; i ++){
    //         const cursor = document.querySelector('.Cursor');
    //         liens[i].addEventListener('mouseover', function(){
    //           console.log('on link')
    //           cursor.classList.add('expand');
    //         })
        
    //         liens[i].addEventListener('mouseout', function(){
    //           cursor.classList.remove('expand')
    //         })
    //       }
    // }

    render(){
        return(
            <div>
                {/* Page transitions */}
                <motion.div
                    className = "fixed pageEnter"
                    initial = {{ y:'0', zIndex:10}}
                    animate = {{y:'-100vw'}}
                    transition = {{delay: 1, duration: 0.6, ease : Power3.easeIn}}
                 />
                <motion.div
                    className = "fixed pageExit"
                    animate = {{opacity: 0}}
                    exit = {{opacity : 1}}
                    transition = {{duration: 1, ease : Power3.easeOut}}
                 />

                <div className = "Homepage">
                
                    <Navbar />
                    <HeroBanner/>
                    <Awards/>
                    <Footer/>
                </div>
                <div className = "HireMe hidden">
                    <HireMe/>
                </div>
            </div>
        )
    }
}

export default Homepage